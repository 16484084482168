import React, { useState } from 'react'

import { Link } from 'react-router-dom'
import { useAuth } from '../../contexts/AuthContext'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import CheckBox from '../util/CheckBox'
import Slogan from './Slogan'

const schema = z.object({
  login: z.string().min(1, 'Invalid Login'),
  password: z.string().min(6, 'Password must be at least 6 characters'),
})

type LoginField = z.infer<typeof schema>

export default function Login() {
  const { login } = useAuth()
  // const navigate = useNavigate()
  const [remember, setRemember] = useState(false)
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
  } = useForm<LoginField>({
    defaultValues: {
      // login: 'waynesan717@gmail.com',
      // login: 'sandstorm55',
      // password: 'wayne1',
    },
    resolver: zodResolver(schema),
  })

  const onSubmit = async (data: LoginField) => {
    // Result True or False
    data['remember'] = remember ? 1 : 0
    const result = await login(data)

    if (!result) {
      setError('password', {
        type: 'manual',
        message: 'Incorrect Password or Username or Email!',
      })
      setError('login', {
        type: 'manual',
        message: 'Incorrect Password or Username or Email!',
      })
    } else {
      // redirect('/bookmark')
      // navigate('/profile')
      // window.location.href = '#/profile'
    }
  }

  return (
    <>
      <form
        className="max-w-sm mx-auto flex flex-col mt-5 p-2"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Slogan />
        <h1 className="title self-center text-xl font-bold ">Log In</h1>
        <label className="label">Email or Username</label>
        <input
          type="text"
          className={`textInput ${errors.login && 'errorInput'}`}
          placeholder="Email or Username"
          autoCapitalize="off"
          autoComplete="off"
          autoCorrect="off"
          {...register('login', { required: true })}
        />
        <p className="error">{errors.login?.message}</p>
        <label className="label">Password</label>
        <input
          className={`textInput ${errors.password && 'errorInput'}`}
          type="password"
          placeholder="Password"
          {...register('password', { required: true })}
        />
        <p className="error">{errors.password?.message}</p>
        <CheckBox
          checkMark={remember}
          setChecked={setRemember}
          content={'Remember this Device.'}
        />

        <div className="text mb-3">
          <Link className="link" to="/forgotPassword">
            Forgot Password?
          </Link>
        </div>
        <button
          disabled={isSubmitting}
          className="button self-center"
          type="submit"
        >
          {isSubmitting ? '....' : 'Log In'}
        </button>
        <div className="text self-center">
          Need an Account?{' '}
          <Link className="link" to="/signup">
            Sign Up
          </Link>
        </div>
      </form>
    </>
  )
}
