import React, { useContext, useEffect, useState } from 'react'
import { TagSortOrder } from '../components/types/TypeInterface'

const DarkModeContext = React.createContext(null)

export function useDarkMode() {
  return useContext(DarkModeContext)
}

export function DarkModeProvider({ children }: { children: React.ReactNode }) {
  const [darkTheme, setDarkTheme] = useState(true)
  const [logoImgUrl, setlogoImgUrl] = useState('')

  function getLocalTheme() {
    const theme = localStorage.getItem('theme')

    if (theme === undefined) {
      setDarkTheme(true)
      setLocalTheme(true)
      return true
    } else {
      setDarkTheme(theme === '1' ? true : false)
      return theme
    }

    /* chrome.storage.local.get(['theme'], (res) => {
      if (res.theme == undefined) {
        setDarkTheme(true)
        setLocalTheme(true)
      } else {
        setDarkTheme(res.theme)
      }
    }) */
  }

  function setLocalTheme(theme: boolean) {
    localStorage.setItem('theme', theme ? '1' : '0')
    setDarkTheme(theme)
    /* chrome.storage.local.set({ theme: theme }, () => {
      setDarkTheme(theme)
    }) */
  }

  function toggleTheme() {
    setLocalTheme(!darkTheme)
  }

  // Get Local
  async function getLocalBookmarkData() {
    return false
    /* return new Promise((resolve, reject) => {
      chrome.storage.local.get(['bookmark'], async (res) => {
        const bookmark = await res.bookmark
        const aBook = {
          ogTitle: bookmark.title,
          faviconUrl: bookmark.favIconUrl,
          url: bookmark.url,
        }
        resolve(aBook)
      })
    }) */
  }

  function openOption() {
    // chrome.tabs.create({ url: chrome.runtime.getURL('option/options.html') })
  }
  function checkPopup() {
    return false
    /* return new Promise((resolve, reject) => {
      chrome.windows.getCurrent((res) => {
        if (res.type == 'popup') {
          resolve(true)
        } else {
          resolve(false)
        }
      })
    }) */
  }

  function getLocalSearchData() {
    // Get Search Data
    const search = localStorage.getItem('search')
    return JSON.parse(search)
    /* return new Promise((resolve, reject) => {
      chrome.storage.local.get(['search'], (res) => {
        const search = res.search
        resolve(search)
      })
    }) */
  }
  function setLocalSearchData(sortOrder: TagSortOrder) {
    // Set Search Data
    localStorage.setItem('search', JSON.stringify(sortOrder))
    /* chrome.storage.local.set({ search: sortOrder }, () => {

    }) */
  }

  useEffect(() => {
    // Get User
    // if (window.location.href.includes('chrome-extension')) {
    //   // Chrome extension
    //   // setlogoImgUrl(chrome.runtime.getURL('icon.png'))
    // }
    // setlogoImgUrl('./icon.png')
    setlogoImgUrl('/icon.png')
    getLocalTheme()
    // eslint-disable-next-line
  }, [])

  const value = {
    darkTheme,
    checkPopup,
    openOption,
    toggleTheme,
    logoImgUrl,
    getLocalBookmarkData,
    setLocalSearchData,
    getLocalSearchData,
  }

  return (
    <DarkModeContext.Provider value={value}>
      <span className={`${darkTheme && 'dark'}`}>
        <div className="body">{children}</div>
      </span>
    </DarkModeContext.Provider>
  )
}
