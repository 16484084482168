import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useAuth } from '../../contexts/AuthContext'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'

const schema = z.object({
  email: z.string().email(),
})

type ForgetPasswordField = z.infer<typeof schema>
export default function ForgotPassword() {
  const { forgotPassword } = useAuth()
  const [sended, setSended] = useState(false)
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
  } = useForm<ForgetPasswordField>({
    defaultValues: {
      // email: 'waynesan717@gmail.com',
    },
    resolver: zodResolver(schema),
  })

  const onSubmit = async (data: ForgetPasswordField) => {
    const result = await forgotPassword(data)
    if (result.email === 'none') {
      // Email Not Found Error
      setError('email', {
        type: 'manual',
        message: 'Email Not Found',
      })
    } else if (result.email === 'sended') {
      setSended(true)
    }
  }

  return (
    <>
      <form
        className="max-w-sm mx-auto flex flex-col mt-5 p-2"
        onSubmit={handleSubmit(onSubmit)}
      >
        <label className="label">Email</label>
        <input
          type="text"
          className={`textInput ${errors.email && 'errorInput'}`}
          placeholder="Email"
          {...register('email', { required: true })}
        />
        <p className="error">{errors.email?.message}</p>
        {/* <p className="error">{errors.email?.message}</p> */}
        {sended && (
          <p className="success">Reset Password Link is Send to you E-mail!</p>
        )}
        <button
          disabled={isSubmitting || sended}
          className="button self-center mt-2"
          type="submit"
        >
          {isSubmitting
            ? 'Sending....'
            : sended
            ? 'E-mail Sended.'
            : 'Reset Password'}
        </button>

        <div className="text self-center">
          Need an Account?{' '}
          <Link className="link" to="/signup">
            Sign Up
          </Link>
        </div>
        <div className="text self-center">
          Already have an Account?{' '}
          <Link className="link" to="/login">
            Log In
          </Link>
        </div>
      </form>
    </>
  )
}
