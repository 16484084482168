import React, { useState } from 'react'
import HowToAddBookmarkDialog from '../bookmark/HowToAddBookmarkDialog'

export default function Slogan() {
  const [howToDialog, setHowToDialog] = useState(false)

  const easyAdd1 = (
    <>
      <button
        type="button"
        onClick={() => {
          setHowToDialog(true)
        }}
        // className="ml-3 text-2xl text-white"
        className="link2 md:inline-block dark:hover:bg-white hover:bg-gray-400"
      >
        {/* &#9655; */}
        &#x25B6;Manage Bookmarks&#x25B6;
      </button>
    </>
  )
  return (
    <>
      <h1 className="text-2xl titleLogin mb-3 md:whitespace-nowrap text-center">
        <i>
          <b>HandyBookmark.</b>
        </i>{' '}
        The Best{' '}
        <a
          className="link2 md:inline-block hidden"
          href="https://chromewebstore.google.com/detail/handy-bookmark/eoaminfjobnfghjcdhpcjndoakidhgod"
          target="_blank"
          rel="noreferrer"
        >
          {/* <span className="no-underlin">&#129513;</span> */}
          {/* <span className="no-underlin">&#129513;</span> */}
          <span className="underline"> Chrome-Extension </span>
        </a>{' '}
        to {easyAdd1}.
      </h1>
      {howToDialog && (
        <HowToAddBookmarkDialog setHowToDialog={setHowToDialog} />
      )}
    </>
  )
}
