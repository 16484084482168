import React from 'react'
import { Link } from 'react-router-dom'

export default function CheckBoxTermPolicy() {
  return (
    <>
      <div className="flex items-center my-3">
        <input
          id="link-checkbox"
          type="checkbox"
          value=""
          className="w-6 h-6 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
          required
        />
        <label className="ms-2 text-base font-medium text-gray-900 dark:text-gray-300">
          I agree with the{' '}
          <Link
            to="/privatePolicy"
            target="_blank"
            className="text-blue-600 dark:text-blue-500 hover:underline"
          >
            Private Policy
          </Link>{' '}
          and{' '}
          <Link
            to="/termsAndConditions"
            target="_blank"
            className="text-blue-600 dark:text-blue-500 hover:underline"
          >
            Terms and Conditions
          </Link>
          .
        </label>
      </div>
    </>
  )
}
