import React from 'react'
import { useAuth } from '../../contexts/AuthContext'
import { Navigate, Outlet } from 'react-router-dom'
import Loading from '../util/Loading'

function PublicRoute() {
  const { loading, currentUser } = useAuth()

  return loading ? (
    <div className="title text-2xl self-center">
      <Loading /> Loading.........
    </div>
  ) : currentUser ? (
    <Navigate to={'/bookmark'} />
  ) : (
    <Outlet />
  )
}

export default PublicRoute
