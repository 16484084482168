import React from 'react'
import { useAuth } from '../../contexts/AuthContext'
import { Navigate, Outlet } from 'react-router-dom'
import Loading from '../util/Loading'

function PrivateRoute() {
  const { loading, currentUser } = useAuth()

  return loading ? (
    <>
      <div className="title text-3xl">
        <Loading /> Loading Data.......
      </div>
    </>
  ) : currentUser ? (
    <>
      <Outlet />
    </>
  ) : (
    // <h2>Login </h2>
    <Navigate to={'/login'} />
  )
}

export default PrivateRoute
