import React, { useContext, useEffect, useState } from 'react'
import { useAuth } from './AuthContext'
import { useTag } from './TagContext'
import type { TagType } from './TagContext'
import { useDarkMode } from './DarkModeContext'

const BookmarkContext = React.createContext(null)

export type Bookmark = {
  bookmarkId: null | number
  ogTitle: string
  myTitle: string
  faviconUrl: string
  url: string
  tags: number[]
  description: string | null
  createTime: number
  lastEdit: number
}

export function useBookmark() {
  return useContext(BookmarkContext)
}

export function BookmarkProvider({ children }) {
  const { fetchRequest, updateBookmarkCount } = useAuth()
  const { tag } = useTag()
  const { setLocalSearchData, getLocalSearchData } = useDarkMode()

  const [bookmark, setBookmark] = useState<Bookmark[] | null>(null)
  const [searchTag, setSearchTag] = useState<TagType[]>([]) // Store Which ID
  const [searchTUD, setSearchTUD] = useState({})
  const [sort, setSort] = useState('c')
  const [order, setOrder] = useState(true)
  const [page, setPage] = useState(1)
  const [openForm, setOpenForm] = useState(false)
  // const [editBookmark, setEditBookmark] = useState()
  // const [editTag, setEditTag] = useState<Tag[]>([])

  // Search Bookmarks ================================================================================
  async function searchBookmark(data) {
    const result = await fetchRequest('/bookmark/search', 'POST', data)

    // if (data.page > page) {
    if (data?.page !== 1 && result) {
      // Add to Current Bookmark
      if (result?.length < 25) {
        setPage(-1)
      } else {
        setPage(page + 1)
      }

      const newResult = result.filter((dBook) => {
        return (
          bookmark.findIndex(
            (oBook) => dBook.bookmarkId === oBook.bookmarkId
          ) === -1
        )

        // const index = bookmark.findIndex(
        //   (oBook) => dBook.bookmarkId === oBook.bookmarkId
        // )
        // if (index === -1) return dBook
      })

      setBookmark([...bookmark, ...newResult])
    } else {
      setBookmark(result)
    }
  }

  // Search URL ================================================================================
  async function searchURL(data) {
    const result = await fetchRequest('/bookmark/searchUrl', 'POST', data)

    if (result === undefined || result?.length === 0) {
      return false
    } else {
      return result[0]
    }
  }

  // Update Bookmark ================================================================================
  async function updateBookmark(data: Bookmark) {
    const result = await fetchRequest('/bookmark/update', 'PATCH', data)
    console.log(data)
    if (result?.bookmark === 'updated') {
      if (bookmark !== null) {
        setBookmark(
          bookmark.map((aBookmark: Bookmark) => {
            if (aBookmark.bookmarkId === data.bookmarkId) {
              return {
                ...aBookmark,
                bookmarkId: data.bookmarkId,
                ogTitle: data.ogTitle,
                myTitle: data.myTitle,
                faviconUrl: data.faviconUrl,
                url: data.url,
                tags: data.tags,
                description: data.description,
                createTime: data.createTime,
                lastEdit: new Date().getTime(),
              }
            } else {
              return aBookmark
            }
          })
        )
      }
      return { bookmark: 'updated' }
    } else if (result?.message === 'Validation failed.') {
      return { bookmark: 'invalid' }
    } else {
      return { bookmark: 'error' }
    }
    // setBookmark(result)
  }
  // ADD Bookmark ================================================================================
  async function addNewBookmark(data: Bookmark) {
    const result = await fetchRequest('/bookmark/add', 'POST', data)
    data.createTime = new Date().getTime()

    if (result?.bookmarkId >= 0) {
      if (bookmark) {
        data['bookmarkId'] = result.bookmarkId
        setBookmark([data, ...bookmark])
      } else {
        setBookmark([data])
      }
      updateBookmarkCount(1)
      return { bookmark: 'added' }
    } else {
      return { bookmark: 'fail' }
    }
    // setBookmark(result)
  }
  // DELETE Bookmark ================================================================================
  async function deleteBookmark(data) {
    const result = await fetchRequest('/bookmark/delete', 'DELETE', data)

    if (result?.bookmark === 'deleted') {
      setBookmark(
        bookmark?.filter((aBook) => aBook.bookmarkId !== data.bookmarkId)
      )
      // setEditBookmark(null)
      // setEditTag(null)
      updateBookmarkCount(-1)
      return { bookmark: 'deleted' }
    } else {
      return { bookmark: 'fail' }
    }
    // setBookmark(result)
  }

  async function getSortOrder() {
    const search = await getLocalSearchData()

    if (search === null || search === undefined) {
      setSort('c')
      setOrder(true)
      setSortOrder({ sort: 'c', order: true })
    } else {
      setSort(search.sort)
      setOrder(search.order)
    }
  }
  function setSortOrder(sortOrder) {
    setLocalSearchData(sortOrder)
  }

  useEffect(() => {
    async function setUp() {
      await getSortOrder()
    }
    setUp()
    // eslint-disable-next-line
  }, [tag])

  const value = {
    searchTag,
    setSearchTag,
    order,
    setOrder,
    sort,
    setSort,
    page,
    setPage,
    searchBookmark,
    searchTUD,
    setSearchTUD,
    bookmark,
    setBookmark,
    openForm,
    setOpenForm,
    // newId,
    // editBookmark,
    // setEditBookmark,
    updateBookmark,
    deleteBookmark,
    // editTag,
    // setEditTag,
    searchURL,
    addNewBookmark,
    setSortOrder,
    getSortOrder,
  }

  return (
    <BookmarkContext.Provider value={value}>
      {children}
    </BookmarkContext.Provider>
  )
}
