import React from 'react'

import { Link, redirect } from 'react-router-dom'
import { useAuth } from '../../contexts/AuthContext'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import CheckBoxTermPolicy from './CheckBoxTermPolicy'
import Slogan from './Slogan'

const schema = z
  .object({
    name: z.string().min(1).max(200),
    username: z
      .string()
      .min(3)
      .regex(/^[0-9a-z_.]*$/, 'Username can only include _ . 0-9 a-z'),
    email: z.string().email(),
    password: z.string().min(6, 'Password must be at least 6 characters'),
    confirmPassword: z
      .string()
      .min(6, 'Password must be at least 6 characters'),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: "Passwords don't match",
    path: ['confirmPassword'],
  })
type SignupField = z.infer<typeof schema>

export default function Signup() {
  const { signup } = useAuth()
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
  } = useForm<SignupField>({
    defaultValues: {
      // name: 'Luffy',
      // email: 'wayne1@gg.com',
      // username: 'ss2',
      // password: 'apple123',
      // confirmPassword: 'apple123',
    },
    resolver: zodResolver(schema),
  })

  const onSubmit = async (data: SignupField) => {
    // Result True or False
    const result = await signup(data)

    if (result.email || result.username) {
      if (result.email === 'taken') {
        setError('email', {
          type: 'manual',
          message: 'Email Already Taken!',
        })
      }
      if (result.username === 'taken') {
        setError('username', {
          type: 'manual',
          message: 'Username Already Taken!',
        })
      }
    } else {
      redirect('/bookmark')
    }
  }

  return (
    <>
      <form
        className="max-w-md mx-auto flex flex-col mt-5 p-2"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Slogan />
        <h1 className="title self-center text-xl font-bold ">Sign Up</h1>
        <label className="label">Full Name</label>
        <input
          type="text"
          className={`textInput ${errors.name && 'errorInput'}`}
          placeholder="Full Name"
          {...register('name', { required: true })}
        />
        <p className="error">{errors.name?.message}</p>

        <label className="label">Email</label>
        <input
          type="text"
          className={`textInput ${errors.email && 'errorInput'}`}
          placeholder="Email"
          {...register('email', { required: true })}
        />
        <p className="error">{errors.email?.message}</p>

        <label className="label">Username</label>
        <input
          type="text"
          className={`textInput ${errors.username && 'errorInput'}`}
          placeholder="Username"
          {...register('username', { required: true })}
        />
        <p className="error">{errors.username?.message}</p>

        <label className="label">Password</label>
        <input
          className={`textInput ${errors.password && 'errorInput'}`}
          type="password"
          placeholder="Password"
          {...register('password', { required: true })}
        />
        <p className="error">{errors.password?.message}</p>

        <label className="label">Confirm Password</label>
        <input
          className={`textInput ${errors.confirmPassword && 'errorInput'}`}
          type="password"
          placeholder="Confirm Password"
          {...register('confirmPassword', { required: true })}
        />
        <p className="error">{errors.confirmPassword?.message}</p>

        <CheckBoxTermPolicy />
        <button
          disabled={isSubmitting}
          className="button self-center mt-2"
          type="submit"
        >
          {isSubmitting ? '....' : 'Sign Up'}
        </button>
        <div className="text self-center">
          Already have an Account?{' '}
          <Link className="link" to="/login">
            Log In
          </Link>
        </div>
      </form>
    </>
  )
}
