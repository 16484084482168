import React, { useEffect, useRef } from 'react'

export default function ServerErrorDialog({ serverError, setServerError }) {
  const dialogRef = useRef<HTMLDialogElement>(null)

  useEffect(() => {
    if (serverError) {
      setServerError(true)
      dialogRef.current.showModal()
    }
    // eslint-disable-next-line
  }, [serverError])

  function hideDialog() {
    dialogRef.current.close()
    document.body.style.overflow = 'unset'
    setServerError(false)
  }
  return (
    <>
      <dialog
        ref={dialogRef}
        className="bookmarkForm2"
        onKeyDown={(e) => {
          if (e.key === 'Escape') {
            e.preventDefault()
            // write your logic here.
          }
        }}
        onClick={(e) => {
          if (e.currentTarget === e.target) {
            hideDialog()
          }
        }}
      >
        <div className="relative p-4 w-full max-w-md max-h-full">
          <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <button
              type="button"
              onClick={hideDialog}
              className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
              data-modal-hide="popup-modal"
            >
              &#10006;
              <span className="sr-only">Close modal</span>
            </button>
            <div className="p-4 md:p-5 text-center">
              <span className="text-9xl mb-3">&#128295;</span>
              <br />
              <h3 className="my-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                Sorry the server is down.
              </h3>
              <button
                data-modal-hide="popup-modal"
                type="button"
                onClick={hideDialog}
                className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </dialog>
    </>
  )
}
