import React from 'react'
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom'

import { AuthProvider } from '../contexts/AuthContext'
import { DarkModeProvider } from '../contexts/DarkModeContext'
import { BookmarkProvider } from '../contexts/BookmarksContext'
import { TagProvider } from '../contexts/TagContext'

/* import PrivateRoute from './routes/PrivateRoute'
import PublicRoute from './routes/PublicRoute'
import Signup from './frontPages/Signup'
import Login from './frontPages/Login'
import ForgotPassword from './frontPages/ForgotPassword'

import Bookmark from './bookmark/Bookmark'
import Tag from './tag/Tag'
import Profile from './profile/Profile'

import AddChromeBookmark from './bookmark/formBookmark/AddChromeBookmark'
import Menu from './menuBar/Menu'
import AddBookmarkMenu from './menuBar/AddBookmarkMenu'

import ResetPassword from './frontPages/ResetPassword'
import VerifyEmail from './frontPages/VerifyEmail'
import TermsAndConditions from './policy/TermsAndConditions'
import PrivatePolicy from './policy/PrivatePolicy' */

import PrivateRoute from './routes/PrivateRoute'
import PublicRoute from './routes/PublicRoute'
import Signup from './frontPages/Signup'
import Login from './frontPages/Login'
import ForgotPassword from './frontPages/ForgotPassword'

const Bookmark = React.lazy(() => import('./bookmark/Bookmark'))
const Tag = React.lazy(() => import('./tag/Tag'))
const Profile = React.lazy(() => import('./profile/Profile'))

const AddChromeBookmark = React.lazy(
  () => import('./bookmark/formBookmark/AddChromeBookmark')
)
const Menu = React.lazy(() => import('./menuBar/Menu'))
const AddBookmarkMenu = React.lazy(() => import('./menuBar/AddBookmarkMenu'))

const ResetPassword = React.lazy(() => import('./frontPages/ResetPassword'))
const VerifyEmail = React.lazy(() => import('./frontPages/VerifyEmail'))
const TermsAndConditions = React.lazy(
  () => import('./policy/TermsAndConditions')
)
const PrivatePolicy = React.lazy(() => import('./policy/PrivatePolicy'))

function App() {
  let BASE_URL = ''
  // if (window.location.origin.includes('eoaminfjobnfghjcdhpcjndoakidhgod')) {
  if (window.location.origin.includes(':3000')) {
    // URL to 3002
    const hostName = window.location.hostname
    BASE_URL = `http://${hostName}:3002`
  } else {
    // Keep the Same
    BASE_URL = window.location.origin
  }
  // const BASE_URL = process.env.BASE_URL

  return (
    <>
      <DarkModeProvider>
        <BrowserRouter>
          <AuthProvider BASE_URL={BASE_URL}>
            <TagProvider>
              <BookmarkProvider>
                <Routes>
                  <Route
                    element={
                      <>
                        {!window.location.href.includes('addBookmark') ? (
                          <Menu />
                        ) : (
                          <AddBookmarkMenu />
                        )}
                        <Outlet />
                      </>
                    }
                  >
                    <Route element={<PrivateRoute />}>
                      <Route path="/bookmark" element={<Bookmark />} />
                      <Route path="/tag" element={<Tag />} />
                      <Route path="/profile" element={<Profile />} />
                      <Route
                        path="/addBookmark"
                        element={<AddChromeBookmark />}
                      />
                      <Route path="/" element={<Bookmark />} />
                      <Route path="*" element={<Bookmark />} />
                    </Route>

                    <Route element={<PublicRoute />}>
                      {/* <Route element={<LinkRoute />}></Route> */}
                      <Route path="/signup" element={<Signup />} />
                      <Route path="/login" element={<Login />} />
                      <Route
                        path="/forgotPassword"
                        element={<ForgotPassword />}
                      />
                      <Route
                        path="/resetPassword/:key"
                        element={<ResetPassword />}
                      />
                    </Route>
                    <Route path="/verifyEmail/:key" element={<VerifyEmail />} />
                    <Route path="/privatePolicy" element={<PrivatePolicy />} />
                    <Route
                      path="/termsAndConditions"
                      element={<TermsAndConditions />}
                    />
                  </Route>
                </Routes>
              </BookmarkProvider>
            </TagProvider>
          </AuthProvider>
        </BrowserRouter>
      </DarkModeProvider>
    </>
  )
}

export default App
