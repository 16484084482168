import React, { useContext, useState } from 'react'
import { useAuth } from './AuthContext'

const TagContext = React.createContext(null)

export type TagType = {
  tagName: string | null
  color: string | null
  tagCount: number | null
  tagId: number | null
}

export function useTag() {
  return useContext(TagContext)
}

export function TagProvider({ children }) {
  const { fetchRequest, updateTagCount } = useAuth()
  const [tag, setTag] = useState<TagType[]>(null)
  // const [openForm, setOpenForm] = useState(false)
  // const [editTag, setEditTag] = useState({
  //   tagName: '',
  //   color: '#F07533',
  // })

  async function getTags() {
    // Set tage Here
    const data = await fetchRequest('/tag/getTag', 'GET')

    setTag(data)
    return 0
  }
  async function deleteTag(data) {
    // Set tage Here
    const result = await fetchRequest('/tag/deleteTag', 'DELETE', data)

    if (result?.tag === 'deleted') {
      setTag(tag.filter((aTag) => aTag.tagId !== data.tagId))
      updateTagCount(-1)
      return { tag: 'deleted' }
    } else {
      return { tag: 'fail' }
    }
  }

  async function addTag(data) {
    const result = await fetchRequest('/tag/addTag', 'POST', data)

    if (result?.tagId >= 0) {
      const newElement = {
        tagId: result.tagId,
        tagName: data.tagName,
        color: data.color,
        tagCount: 0,
      }
      setTag([newElement, ...tag])
      updateTagCount(1)
      return { tag: 'added', tagId: result.tagId }
    } else if (result?.message === 'Validation failed.') {
      return { tag: 'taken' }
    } else {
      return { tag: 'error' }
    }
  }
  async function updateTag(data) {
    const result = await fetchRequest('/tag/updateTag', 'PATCH', data)

    if (result?.tag === 'updated') {
      setTag(
        tag.map((aTag) => {
          if (aTag.tagId === data.tagId) {
            return { ...aTag, tagName: data.tagName, color: data.color }
          } else {
            return aTag
          }
        })
      )
      return { tag: 'added' }
    } else if (result?.message === 'Validation failed.') {
      return { tag: 'taken' }
    } else {
      return { tag: 'error' }
    }
  }

  // useEffect(() => {}, [])

  const value = {
    getTags,
    tag,
    setTag,
    // openForm,
    // setOpenForm,
    // editTag,
    // setEditTag,
    addTag,
    updateTag,
    deleteTag,
  }

  return <TagContext.Provider value={value}>{children}</TagContext.Provider>
}
