import React from 'react'

export default function CheckBox({ checkMark, setChecked, content }) {
  return (
    <>
      <div
        className="flex items-center mt-2 text-base"
        onClick={() => {
          setChecked(!checkMark)
        }}
      >
        <input
          checked={checkMark}
          onChange={() => {}}
          type="checkbox"
          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
        />
        <label className="ms-2 font-medium text-gray-900 dark:text-gray-300">
          {content}
        </label>
      </div>
    </>
  )
}
