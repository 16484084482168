import React, { useEffect, useRef, useState } from 'react'

export default function HowToAddBookmarkDialog({ setHowToDialog }) {
  const dialogRef = useRef<HTMLDialogElement>(null)
  const [confirmDialogState, setConfirmDialogState] = useState<Number>(0)
  const toggleDialog = (open = false) => {
    //Toggle dialog
    if (!dialogRef.current) {
      return
    } else {
      if (!dialogRef.current.hasAttribute('open') || open) {
        dialogRef.current.showModal()
        document.body.style.overflow = 'hidden'
      } else {
        dialogRef.current.close()
        document.body.style.overflow = 'unset'
        setConfirmDialogState(0)
        setHowToDialog(false)
      }
    }
  }

  useEffect(() => {
    toggleDialog(true)
    // eslint-disable-next-line
  }, [])
  return (
    <>
      <dialog
        onCancel={(event) => {
          // IMPORTANT to Avoid Escap
          // event.preventDefault()
          setHowToDialog(false)
          setConfirmDialogState(0)
        }}
        ref={dialogRef}
        onKeyDown={(e) => {
          if (e.key === 'Escape' && confirmDialogState === 0) {
            e.preventDefault()
            // write your logic here.
          }
        }}
        onClick={(e) => {
          if (e.currentTarget === e.target) {
            toggleDialog()
          }
        }}
        className="w-[100vh] flex flex-col"
      >
        <iframe
          className="h-[80vh]"
          src="https://www.youtube.com/embed/s-9rdQMt6rU"
          title="Bookmark a Website with Handy Bookmark"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </dialog>
    </>
  )
}
