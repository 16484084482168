import React, { Suspense, useContext, useEffect, useState } from 'react'

import ServerErrorDialog from '../components/menuBar/ServerErrorDialog'
import { useNavigate } from 'react-router-dom'
import { useDarkMode } from './DarkModeContext'
import Loading from '../components/util/Loading'

const AuthContext = React.createContext(null)

export function useAuth() {
  return useContext(AuthContext)
}
// const BASE_URL = process.env.REACT_APP_BASE_URL

/* type User = {
  email: string
  username: string
  name: string
  password: string
} */

export function AuthProvider({
  children,
  BASE_URL,
}: {
  children: React.ReactNode
  BASE_URL: string
}) {
  const [loading, setLoading] = useState<boolean>(true)
  const [currentUser, setCurrentUser] = useState<any>()
  const [serverError, setServerError] = useState(false)

  const { checkPopup } = useDarkMode()
  const navigate = useNavigate()
  // Log In ================================================================================
  async function login(loginData: any) {
    const result = await fetchRequest('/user/login', 'POST', loginData)

    if (
      result?.username === loginData.login ||
      result?.email === loginData.login
    ) {
      // redirect('/tag')
      const popup = await checkPopup()

      if (popup) {
        setCurrentUser(result)
        navigate('/addBookmark')
      } else {
        setCurrentUser(result)
        navigate('/bookmark')
      }
      // window.location.href = '#/tag'
      // window.location.href = '#/tag'
      // window.location.href = '#/profile'
      return true
    } else {
      return false
    }
  }

  // Log Out ================================================================================
  async function logout() {
    const result = await fetchRequest('/user/logout', 'POST')

    if (result?.message === 'logged out') {
      setCurrentUser(false)
      return false
    } else {
      // setCurrentUser(false)
      return true
    }
  }

  // Sign Up ================================================================================
  async function signup(signupData: any) {
    delete signupData.confirmPassword
    const result = await fetchRequest('/user/signup', 'POST', signupData)

    if (result?.message === 'Sign Up Success.') {
      delete signupData.password
      setCurrentUser(signupData)
      return true
    } else if (result?.message === 'Validation failed.') {
      const invalidData = result?.data
      const errorData = {}
      invalidData.find((field) => {
        if (field.path === 'email' && field.msg === 'taken') {
          errorData['email'] = 'taken'
        } else if (field.path === 'username' && field.msg === 'taken') {
          errorData['username'] = 'taken'
        }
        return null
      })

      return errorData
    } else {
      return false
    }
  }

  // Get User Info ================================================================================
  async function getUserInfo() {
    const user = await fetchRequest('/user/getUser', 'GET')
    setCurrentUser(user)
  }
  // Send Verify Email ================================================================================
  async function sendVerifyEmail() {
    const sendResult = await fetchRequest('/user/sendVerify', 'GET')
    return sendResult
  }

  // Verify Email With Token ================================================================================
  async function verifyEmail(data) {
    const result = await fetchRequest('/user/verifyEmail', 'POST', data)
    if (result?.email === 'verify') {
      return true
    } else {
      return false
    }
  }

  // Send Forgot Password Email ================================================================================
  async function forgotPassword(data) {
    const result = await fetchRequest('/user/forgotPassword', 'POST', data)

    if (result?.message === 'Validation failed.') {
      return { email: 'none' }
    } else if (result?.email === 'sended') {
      return { email: 'sended' }
    } else {
      return { email: 'error' }
    }
  }

  // RESET Password ================================================================================
  async function checkPasswordToken(data) {
    const result = await fetchRequest('/user/checkToken', 'POST', data)
    if (result?.token === 'valid') {
      return true
    } else {
      return false
    }
    /* if (result?.message == 'Validation failed.') {
      return false
    } else if (result?.token == 'invalid') {
      return false
    } else {
      return true
    } */
  }
  // RESET Password ================================================================================
  async function resetPassword(data) {
    const result = await fetchRequest('/user/resetPassword', 'POST', data)

    if (result?.password === 'reset') {
      return true
    } else {
      return false
    }
  }
  // Update Name ================================================================================
  async function updateName(data) {
    const result = await fetchRequest('/user/changeName', 'PATCH', data)

    if (result?.name === 'updated') {
      currentUser.name = data.name
      return true
    } else {
      return false
    }
  }

  // Update Username ================================================================================
  async function updateUsername(data) {
    const result = await fetchRequest('/user/changeUsername', 'PATCH', data)

    if (result?.username === 'updated') {
      currentUser.username = data.username
      return true
    } else {
      return false
    }
  }

  // Update Email ================================================================================
  async function updateEmail(data) {
    const result = await fetchRequest('/user/changeEmail', 'PATCH', data)

    if (result?.email === 'updated') {
      currentUser.email = data.email
      currentUser.verifyEmail = false
      return { email: true }
    } else if (result.message === 'Validation failed.') {
      return { password: 'wrong' }
    } else {
      return { email: false }
    }
  }

  // Update Password ================================================================================
  async function updatePassword(data) {
    const result = await fetchRequest('/user/changePassword', 'PATCH', data)

    if (result?.password === 'updated') {
      return { password: true }
    } else if (result.message === 'wrong') {
      return { password: 'wrong' }
    } else {
      return { password: false }
    }
  }

  // FETCH Request ================================================================================
  async function fetchRequest(path: string, type: string, data?: object) {
    const fetchUrl = BASE_URL + path
    // const fetchUrl = 'http://localhost:3000/user/login'
    // console.log(fetchUrl + '/////////////////')

    const myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')

    const raw = JSON.stringify(data)

    const requestOptions: object = {
      mode: 'cors',
      method: type,
      headers: myHeaders,
      credentials: 'include',
      body: raw,
      // redirect: 'follow',
    }
    const result = await fetch(fetchUrl, requestOptions)
      .then((response) => {
        if (response.status === 200) {
          return response.json()
        } else if (response.status === 401) {
          setCurrentUser(false)
          throw new Error('401')
        } else if (response.status === 422) {
          console.log('!!!!!! Invalid Data from Server !!!!!!')
          // throw new Error('422')
          return response.json()
        } else {
          throw new Error('Server Error')
        }
      })
      .then((result) => {
        return result
      })
      .catch((error) => {
        if (error.message === '401') {
          setCurrentUser(false)
        } else {
          console.log('!!!!!! Different Error !!!!!!')
          setServerError(true)
        }
      })
    return result
  } // End Fetch

  function updateBookmarkCount(change: number) {
    let temp = currentUser
    temp.bookmarkCount = currentUser.bookmarkCount + change
    setCurrentUser(temp)
  }
  function updateTagCount(change: number) {
    let temp = currentUser
    temp.tagCount = currentUser.tagCount + change
    setCurrentUser(temp)
  }
  useEffect(() => {
    setLoading(true)
    // Check User Login
    // Check Cookie First

    const fetchUrl = BASE_URL + '/user/getUser'
    const myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')

    const requestOptions: object = {
      mode: 'cors',
      method: 'GET',
      headers: myHeaders,
      credentials: 'include',
    }
    fetch(fetchUrl, requestOptions)
      .then((response) => {
        if (response.status === 401) {
          setCurrentUser(false)
          // throw new Error('401')
        } else {
          return response.json()
        }
      })
      .then((result) => {
        setCurrentUser(result)
        setLoading(false)
        // window.location.href = '#/bookmark'
        return result
      })
      .catch((error) => {
        setLoading(false)
      })
    // eslint-disable-next-line
  }, [])

  const value = {
    login,
    logout,
    signup,
    currentUser,
    setCurrentUser,
    updateTagCount,
    updateBookmarkCount,
    loading,
    fetchRequest,
    updateName,
    updateUsername,
    updateEmail,
    updatePassword,
    serverError,
    setServerError,
    getUserInfo,
    sendVerifyEmail,
    verifyEmail,
    forgotPassword,
    checkPasswordToken,
    resetPassword,

    // resetPassword,
    // verifyEmail,
  }

  return (
    <AuthContext.Provider value={value}>
      <Suspense fallback={<Loading />}>
        {children}
        <ServerErrorDialog
          serverError={serverError}
          setServerError={setServerError}
        />
      </Suspense>
    </AuthContext.Provider>
  )
}
